import React from 'react';

const Appbar = () => {
      return(
            <nav id="app-bar">
                  <h1>Entertainment Rating App</h1>
            </nav>
      )
}

export default Appbar;